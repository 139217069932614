import * as React from 'react';
import { OpeningHours as GQLOpeningHours } from 'src/gql/graphql';
import { getCombinedOpeningHours } from 'src/utils/getCombinedOpeningHours';
import { useTranslate } from '../../../providers';
import {
  FormattedSpecialHours,
  getFormattedSpecialHours,
} from '../../../utils/getFormattedSpecialHours';
import { useStore } from '../../../hooks';

type OpeningHoursProps = {
  openingHours: GQLOpeningHours | null;
  locale: string;
  selectedCapability?: string;
};

export const OpeningHours = ({
  openingHours: openingHoursData,
  locale,
  selectedCapability = 'retailer',
}: OpeningHoursProps) => {
  const translate = useTranslate();
  const { firstDayOfWeek } = useStore();

  if (!openingHoursData) return null;
  const { specialDates, ...openingHours } = openingHoursData;
  const combinedOpeningHours = getCombinedOpeningHours({
    openingHours,
    locale,
    combineDays: true,
    firstDayOfWeek,
  });
  const formattedSpecialHours: FormattedSpecialHours[] =
    getFormattedSpecialHours(specialDates, locale);

  const title =
    selectedCapability === 'service'
      ? translate(
          'global.retailer.serviceOpeningHours' || 'Service opening hours',
        )
      : translate('global.retailer.salesOpeningHours' || 'Sales opening hours');

  return (
    <>
      <div>
        <p className="font-medium">{title}</p>
        {combinedOpeningHours
          .filter((day) => day.hours && day.hours?.length > 0)
          .map(({ days, hours }, index) => (
            <div
              className="flex justify-between"
              key={index}
              data-testid="dealer:openingHours"
            >
              <p className="text-secondary">
                {days.endDay
                  ? `${days.startDay} - ${days.endDay}`
                  : days.startDay}
              </p>
              <div className="flex-col items-end">
                {hours?.map((h, i) => (
                  <p key={i} className="text-secondary">
                    {h}
                  </p>
                ))}
              </div>
            </div>
          ))}

        {formattedSpecialHours.length > 0 && (
          <div className="mt-24">
            <p className="font-medium">
              {translate('global.retailer.specialHours' || 'Special hours')}
            </p>
            {formattedSpecialHours.map(({ date, timings }, index) => (
              <div className="flex justify-between" key={index}>
                <p className="text-secondary">{date}</p>
                <div className="flex-col items-end">
                  {timings?.map((time, i) => (
                    <p key={i} className="text-secondary">
                      {time}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
