import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useTopbarHeight } from '@vcc-www/hooks';
import { useTracker } from '@volvo-cars/tracking';
import { Retailer } from 'src/types/retailer';
import { SUBMENU_HEIGHT_FROML } from 'src/constants/sizes';
import { useSelectorSettings, useStore } from 'src/hooks';
import { isEqualRetailer } from 'src/utils/isEqualRetailer';
import { updateMapPanBoundsZoom } from 'src/utils/mapUtils';
import { TRACKING_CATEGORY } from 'src/constants/tracking';
import { getDealerId } from '../../../utils/volvoTrackingHelpers';
import ExtendedRetailerCardUI from './ExtendedRetailerCardUI';
import RetailerSelectorCardUI from '../../selector/RetailerSelectorCardUI';

const RetailerCard = ({ retailer }: { retailer?: Retailer }) => {
  const {
    map,
    selectedRetailer,
    retailersListVisible,
    address: currentAddress,
    dispatch,
  } = useStore();
  const selectorSettings = useSelectorSettings();
  const ref = useRef<HTMLDivElement>(null);
  const topbarHeight = useTopbarHeight();
  const track = useTracker();

  useEffect(() => {
    // In the case with expandable cards, we will have to account for a previous card getting minimized.
    // Otherwise, the list might scroll too far when going to the newly selected one.
    const timeout = setTimeout(() => {
      if (!ref.current) return;
      if (isEqualRetailer(retailer, selectedRetailer)) {
        const rect = ref.current.getBoundingClientRect();
        const scrollOffset = SUBMENU_HEIGHT_FROML + topbarHeight - 1;
        const top = rect.top + window.scrollY - scrollOffset;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    }, 1);
    return () => clearTimeout(timeout);
  }, [selectedRetailer, retailer, topbarHeight, retailersListVisible]);

  const longitude = retailer?.longitude;
  const latitude = retailer?.latitude;
  const handleRetailerClick = useCallback(() => {
    if (!latitude || !longitude || !retailer) return;
    updateMapPanBoundsZoom(map, {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
      zoom: 17,
    });
    dispatch({ type: 'SET_SELECTED_RETAILER', payload: retailer });
    track.customEvent({
      eventAction: 'card|select',
      eventCategory: TRACKING_CATEGORY,
      eventLabel: 'retailer card',
      dealerId: getDealerId(retailer),
      dealerInfo: retailer?.name ?? undefined,
    });
  }, [map, latitude, longitude, retailer, dispatch, track]);
  if (!retailer) return null;
  const activeUserAddress = !!(
    currentAddress?.coords?.latitude || currentAddress?.place_id
  );

  return selectorSettings.useSelector ? (
    <RetailerSelectorCardUI
      retailer={retailer}
      wrapperRef={ref}
      selectedRetailer={selectedRetailer}
      handleRetailerClick={handleRetailerClick}
    />
  ) : (
    <ExtendedRetailerCardUI
      retailer={retailer}
      wrapperRef={ref}
      selectedRetailer={selectedRetailer}
      handleRetailerClick={handleRetailerClick}
      activeUserAddress={activeUserAddress}
    />
  );
};
export default memo(RetailerCard);
