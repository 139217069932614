import { OpeningHours, TimeInterval } from 'src/gql/graphql';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Intl {
    interface Locale {
      weekInfo?: {
        firstDay: number;
        minimalDays: number;
        weekend: [number, number];
      };
    }
  }
}

const mondayStartWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const sundayStartWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

type CombinedDay = {
  days: {
    startDay?: string;
    endDay?: string;
  };
  hours?: string[];
};

type GetCombinedOpeningHoursProps = {
  openingHours: Omit<OpeningHours, 'specialDates' | '__typename'>;
  locale: string;
  combineDays?: boolean;
  firstDayOfWeek?: 0 | 1;
};

/**
 * Returns the first day of the week given a locale (Monday or Sunday)
 * @remarks
 * This method must be called server side as is not compatible with Safari browser.
 *
 */
export const getFirstDayOfWeek = (locale: string): 0 | 1 => {
  const weekInfo = new Intl.Locale(locale).weekInfo;
  return weekInfo?.firstDay === 1 ? 1 : 0; // Only sunday or monday expected and supported
};
export const getCombinedOpeningHours = ({
  openingHours,
  locale,
  combineDays,
  firstDayOfWeek = 0,
}: GetCombinedOpeningHoursProps) => {
  const weekDays = firstDayOfWeek === 1 ? mondayStartWeek : sundayStartWeek;

  const combinedDays: CombinedDay[] = [];
  const format = new Intl.DateTimeFormat(locale, { weekday: 'long' }).format;
  const localizedWeekDays = weekDays.reduce(
    (acc, day, i) => {
      // We choose a date which we know starts on a monday vs sunday
      acc[day] = format(
        new Date(
          firstDayOfWeek === 1 ? Date.UTC(2021, 5, i) : Date.UTC(2021, 1, i),
        ),
      );
      return acc;
    },
    {} as Record<string, string>,
  );

  const openingHoursArray: { [x: string]: TimeInterval[] }[] = Object.entries(
    openingHours,
  ).map(([key, value]) => ({ [key]: value }));

  const sortedOpeningHoursArray = openingHoursArray.sort((a, b) => {
    const dayA = Object.keys(a)[0];
    const dayB = Object.keys(b)[0];
    return weekDays.indexOf(dayA) - weekDays.indexOf(dayB);
  });
  const formattedDays: CombinedDay[] = sortedOpeningHoursArray
    .map((obj) => {
      const day = Object.keys(obj)[0];
      const values = Object.values(obj)[0];
      // Check needed due to typescripts inability to mark additional props as invalid for openingHours
      const localizedDay = localizedWeekDays[day];
      return !localizedDay
        ? null
        : {
            days: {
              startDay: localizedDay,
              endDay: undefined,
            },
            hours: values.map(getHours).filter(Boolean),
          };
    })
    .filter(Boolean);

  if (combineDays) {
    formattedDays.forEach((day) => {
      const previousCombinedDay = combinedDays.at(combinedDays.length - 1);

      if (
        previousCombinedDay &&
        previousCombinedDay.hours?.join() === day.hours?.join() &&
        day.days.startDay
      ) {
        previousCombinedDay.days.endDay = day.days.startDay;
      } else {
        combinedDays.push(day);
      }
    });
  }

  return combinedDays.length > 0 ? combinedDays : formattedDays;
};

const getHours = (oH: TimeInterval) => {
  return oH.start && oH.end ? oH.start + '-' + oH.end : undefined;
};
