import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import memoize from 'lodash/memoize';
import { useKeyPress } from '@volvo-cars/react-utils';
import {
  useAddressAutocomplete,
  useSearchInput,
  useSelectorSettings,
  useStore,
  useTranslate,
} from 'src/hooks';
import { useFeatureFlags } from 'src/hooks/useFeatureFlags';
import { StoreContextValue } from 'src/providers';
import {
  Filter,
  SuggestionsBox,
  TextInputWithIcon,
} from 'src/features/dealer-picker';
import styles from './AutocompleteAddress.module.css';

export const AutocompleteAddress = (): JSX.Element => {
  const { dispatch, address, view, suggestionsVisible, headerVisible } =
    useStore();
  const { input, inputRef, setInput } = useSearchInput();
  const { useZipCodeSearch } = useFeatureFlags();
  const { useSelector } = useSelectorSettings();
  const translate = useTranslate();

  const predictions = useAddressAutocomplete(
    input?.value,
    {
      debounceTime: 500,
      skip: input?.skipFetch,
      resetPredictions: input?.resetPredictions,
    },
    useZipCodeSearch,
  );
  const [highlightIndex, setHighlightIndex] = useState(-1);

  const predictionsLength = predictions?.length;
  useEffect(() => {
    if (!predictionsLength) return;
    if (highlightIndex > predictionsLength - 1) {
      setHighlightIndex(-1);
    }
    if (highlightIndex < -1) {
      setHighlightIndex(predictionsLength - 1);
    }
  }, [highlightIndex, predictionsLength]);
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInput({
        value,
        skipFetch: false,
        resetPredictions: false,
      });
    },
    [setInput],
  );
  const blurInput = useCallback(() => {
    inputRef?.current && inputRef?.current.blur();
    setHighlightIndex(-1);
  }, [inputRef]);
  useKeyPress('Escape', blurInput);
  const handlePredictionSelect = useCallback(
    (prediction: StoreContextValue['address']) => {
      dispatch({ type: 'SET_ADDRESS', payload: prediction });
      setInput({
        value: prediction?.description || '',
        skipFetch: false,
        resetPredictions: false,
      });
      blurInput();
    },
    [blurInput, dispatch, setInput],
  );
  const handleInputSubmit = useCallback(() => {
    if (!inputRef?.current?.value.trim().length) {
      inputRef?.current && inputRef?.current.focus();
      return;
    }
    if (!predictionsLength || !predictions) {
      return;
    }
    if (highlightIndex >= 0 && highlightIndex <= predictionsLength - 1) {
      predictions && handlePredictionSelect(predictions[highlightIndex]);
    } else {
      predictions && handlePredictionSelect(predictions[0]);
    }
    blurInput();
  }, [
    inputRef,
    predictionsLength,
    predictions,
    highlightIndex,
    blurInput,
    handlePredictionSelect,
  ]);
  const isUseMyLocation = address?.coords?.longitude;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createFocusAndBlurHandler = useCallback(
    memoize(
      (
        nextInputValue: string,
        skipFetch: boolean,
        suggestionVisible: boolean,
      ) => {
        return () => {
          isUseMyLocation &&
            !predictionsLength &&
            setInput({
              value: nextInputValue,
              skipFetch,
              resetPredictions: false,
            });

          dispatch({
            type: 'SET_SUGGESTIONS_VISIBLE',
            payload: suggestionVisible,
          });
        };
      },
      (...args) => args.join(),
    ),
    [dispatch, predictionsLength, isUseMyLocation, setInput],
  );
  return (
    <>
      <h1
        className={`${view === 'map' && 'until-lg:hidden'} ${!headerVisible && 'lg:hidden'} heading-2 p-24`}
      >
        {translate('global.general.header') || 'Find a Volvo Dealer'}
      </h1>
      <div
        className={`${styles.searchWrapper} sticky bg-primary z-overlay pt-8`}
        style={{
          '--top': 'calc(var(--sitenav-topbar-height,48px) - 1px)',
          isolation: 'isolate',
        }}
      >
        <div>
          <TextInputWithIcon
            iconType="search"
            label={
              useZipCodeSearch
                ? `${
                    translate('search.form.zipInputPlaceholder') || 'Zip code'
                  }`
                : `${translate('search.form.inputPlaceholder') || 'Search'}`
            }
            value={input.value}
            inputRef={inputRef}
            onChange={handleInputChange}
            handleSubmit={handleInputSubmit}
            autoComplete="off"
            onFocus={createFocusAndBlurHandler('', false, true)}
            onBlur={createFocusAndBlurHandler(
              translate('search.form.myLocation') || 'My location',
              true,
              false,
            )}
          />
          <SuggestionsBox
            setInput={setInput}
            suggestionsVisible={suggestionsVisible}
            handlePredictionSelect={handlePredictionSelect}
            predictions={predictions}
            highlightIndex={highlightIndex}
          />
        </div>
        {!useSelector && <Filter />}
      </div>
    </>
  );
};
