import { useEffect, useState } from 'react';
import { useBreakpoints } from '@vcc-www/hooks';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Retailer } from 'src/types/retailer';
import { ExtendedPhoneNumber } from 'src/features/dealer-picker/retailerCard/PhoneNumber';
import {
  BOTTOM_BAR_HEIGHT,
  DEFAULT_CARD_HEIGHT,
  MAP_RETAILER_CARD_AND_SEARCH_BAR_GAP,
  SUBMENU_HEIGHT,
} from 'src/constants/sizes';
import {
  useRetailerLinks,
  useSelectorSettings,
  useStore,
  useTranslate,
} from 'src/hooks';
import { useCapabilitySelection } from 'src/hooks/useCapabilitySelection';
import { getValidSiteSlug } from 'src/utils/getValidSiteSlug';
import { getDealerId } from 'src/utils/volvoTrackingHelpers';
import { formatDistance } from 'src/utils/formatDistance';
import styles from './MapRetailerCard.module.css';
import {
  CapabilityTabs,
  GetDirectionsLink,
  OpeningHours,
  RetailerHeading,
  VisitRetailerLink,
} from '../..';
import { RetailerLinksGroup } from './RetailerLinksGroup';
import DistanceAddressRow from './DistanceAddressRow';

export const MapRetailerCard = () => {
  const [expand, setExpand] = useState(false);
  const { selectedRetailer, view, address: currentAddress } = useStore();
  const { fromM } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints
  const { locale } = useCurrentMarketSite();
  const selectorSettings = useSelectorSettings();
  useEffect(() => {
    fromM && setExpand(true);
  }, [selectedRetailer, fromM]);

  if (view !== 'map' || !selectedRetailer) return null;
  const activeUserAddress = !!(
    currentAddress?.coords?.latitude || currentAddress?.place_id
  );
  const handleToggle = () => setExpand((expand) => !expand);

  return selectorSettings.useSelector ? (
    <MapRetailerSelectorUI
      handleToggle={handleToggle}
      handleRetailerSelect={() =>
        selectorSettings.onRetailerSelect(selectedRetailer)
      }
      {...selectedRetailer}
    />
  ) : (
    <ExtendedMapRetailerCardUI
      activeUserAddress={activeUserAddress}
      expand={expand}
      handleToggle={handleToggle}
      locale={locale}
      retailer={selectedRetailer}
    />
  );
};

const ExtendedMapRetailerCardUI = ({
  activeUserAddress,
  expand,
  handleToggle,
  locale,
  retailer,
}: {
  activeUserAddress: boolean;
  expand: boolean;
  handleToggle: () => void;
  locale: string;
  retailer: Retailer;
}) => {
  const { roadLengthUnit, siteSlug } = useCurrentMarketSite();
  const validSiteSlug = getValidSiteSlug(siteSlug);
  const retailerLinks = useRetailerLinks(retailer, validSiteSlug);
  const {
    addressLine1,
    addressLine2,
    capabilities,
    coordinatesMapLink,
    distanceFromPointKm,
    distanceFromPointMiles,
    latitude,
    longitude,
    name,
    url,
    mapLocation,
    openingHours,
    phoneNumbers,
  } = retailer;
  const distance =
    roadLengthUnit === 'mile' ? distanceFromPointMiles : distanceFromPointKm;
  const {
    setSelectedCapability,
    selectedOpeningHours,
    selectedPhoneNumber,
    selectedCapability,
  } = useCapabilitySelection({ openingHours, phoneNumbers });

  return (
    <div
      className={`${styles.wrapper} w-full bg-primary rounded-t-lg overflow-y-auto lg:hidden`}
      data-testid="dealer:mapViewRetailerContainer"
      style={{
        '--bottom': `${BOTTOM_BAR_HEIGHT}px`,
        '--padding': `${retailerLinks.length > 0 ? '2rem 2rem 1rem' : '1.5rem'}`,
        '--max-height': `calc(100dvh - ${SUBMENU_HEIGHT}px - ${BOTTOM_BAR_HEIGHT}px - var(--sitenav-topbar-height, 48px) - ${MAP_RETAILER_CARD_AND_SEARCH_BAR_GAP}px)`,
        '--transform': expand
          ? `translateY(0)`
          : `translateY(100%) translateY(-${BOTTOM_BAR_HEIGHT + DEFAULT_CARD_HEIGHT}px)`,
      }}
    >
      <div className="flex-col gap-16 mb-24">
        <div
          role="button"
          className="flex-col gap-16"
          tabIndex={0}
          onKeyDown={() => !expand && handleToggle()}
          onClick={() => !expand && handleToggle()}
        >
          <RetailerHeading
            retailerName={name}
            isExpanded={expand}
            onClose={handleToggle}
          />
          <CapabilityTabs
            capabilities={capabilities.filter(Boolean)}
            onCapabilityChanged={setSelectedCapability}
          />
          <DistanceAddressRow
            distance={
              activeUserAddress
                ? formatDistance(
                    distance || 0,
                    locale,
                    roadLengthUnit ?? 'kilometer',
                  )
                : undefined
            }
            isMapCard
            addressLine1={addressLine1}
            addressLine2={addressLine2}
          />
        </div>
        <ExtendedPhoneNumber name={name} phoneNumber={selectedPhoneNumber} />
        <OpeningHours
          openingHours={selectedOpeningHours}
          locale={locale}
          selectedCapability={selectedCapability}
        />
      </div>
      <div className="grid md:grid-cols-2 md:gap-24 gap-8 mt-16">
        {url && <VisitRetailerLink name={name} url={url} />}
        <GetDirectionsLink
          name={name}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          coordinatesMapLink={coordinatesMapLink}
          longitude={longitude}
          latitude={latitude}
          url={mapLocation}
        />
      </div>
      {retailerLinks.length > 0 && (
        <RetailerLinksGroup
          dealerId={getDealerId(retailer)}
          dealerInfo={name}
          links={retailerLinks}
        />
      )}
    </div>
  );
};

const MapRetailerSelectorUI = ({
  handleRetailerSelect,
  name,
  addressLine1,
  addressLine2,
}: {
  handleToggle: () => void;
  handleRetailerSelect: () => void;
  name: string;
  addressLine1: string;
  addressLine2: string;
}) => {
  const translate = useTranslate();
  return (
    <div
      className={`${styles.wrapper} w-full bg-primary rounded-t-lg overflow-y-auto lg:hidden`}
      data-testid="dealer:mapViewRetailerContainer"
      style={{
        '--bottom': `${BOTTOM_BAR_HEIGHT}px`,
        '--padding': '1.5rem',
        '--max-height': `calc(100vh - ${SUBMENU_HEIGHT}px - ${BOTTOM_BAR_HEIGHT}px - var(--sitenav-topbar-height, 48px) - 5px)`,
      }}
    >
      <div className="overflow-hidden">
        <p
          className="font-medium"
          data-testid="dealer:name"
          aria-label={
            translate('global.retailer.retailerNameAriaLabel', {
              retailerName: name,
            }) || `${name} Click to view on map`
          }
        >
          {name}
        </p>
        <p className="text-secondary pb-8" data-testid="dealer:address">
          {addressLine1} {addressLine2}
        </p>
      </div>
      <button
        className="button-filled"
        onClick={handleRetailerSelect}
        data-size="small"
        type="button"
      >
        {translate('global.retailer.selectRetailer') || 'Select retailer'}
      </button>
    </div>
  );
};
